import React from 'react';
import PropTypes from 'prop-types';

import { Container, Input, Label, UnderLine, Textarea, ErrorMessage } from './FormField.style';

const propTypes = {
  onInputChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  textarea: PropTypes.bool,
  error: PropTypes.string,
  rows: PropTypes.number,
};

const defaultProps = {
  type: 'text',
  name: 'Default input',
  text: 'Default text',
  error: '',
  textarea: false,
  rows: 4,
};

const InputForm = ({
  onInputChange,
  value,
  type = undefined,
  name,
  text,
  error,
  textarea,
  rows = undefined,
}) => (
  <Container>
    {textarea ? (
      <Textarea onChange={onInputChange} rows={rows} name={name} value={value} />
    ) : (
      <Input onChange={onInputChange} name={name} type={type} value={value} />
    )}
    <Label htmlFor={name} valid={value.length}>
      {text}
    </Label>
    <UnderLine />
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </Container>
);

InputForm.propTypes = propTypes;
InputForm.defaultProps = defaultProps;

export default InputForm;
