import Styled from 'styled-components';

import { colors, breakpoints } from '../../styles/variables';

export const Wrapper = Styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${colors.darkShade};
  padding: 2rem;
  overflow: auto;

  @media ${breakpoints.tabletPortrait} {
    padding: 5rem;
  }
  @media ${breakpoints.tabletLandscape} {
    flex-direction: row;
  }
`;

export const Header = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
`;

export const ContactForm = Styled.form`
  max-width: 70rem;
  width: 100%;
  padding: 2rem 0;
`;

export const Image = Styled.img`
  display: flex;
  flex: 1;
  height: 70%;
  width: 70%;

  @media ${breakpoints.tabletPortrait} {
    height: 55%;
    max-width: 55%;
  }

  @media ${breakpoints.tabletLandscape} {
    height: 75%;
    max-width: 75%;
  }

  @media ${breakpoints.desktop} {
    height: 65%;
    max-width: 65%;
  }

  @media ${breakpoints.desktopL} {
    height: 5%;
    max-width: 50%;
  }
`;

export const Column = Styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: ${({ left }) => (left ? 'flex-start' : 'center')};
  justify-content: center;
  width: 100%;
  height: 100%;

  @media ${breakpoints.tabletLandscape} {
    max-width: 50%;
  }
`;

export default {
  Header,
  ContactForm,
  Wrapper,
};
