import Styled from 'styled-components';
import { CheckBox as CheckboxCheckedIcon } from '@styled-icons/material/CheckBox';
import { CheckBoxOutlineBlank as CheckboxIcon } from '@styled-icons/material/CheckBoxOutlineBlank';

import { colors, breakpoints } from '../../styles/variables';

export const HiddenCheckbox = Styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Icon = Styled(CheckboxIcon)`
  fill: ${colors.lightAccent};
  width: 3rem;
`;

export const IconChecked = Styled(CheckboxCheckedIcon)`
  fill: ${colors.lightAccent};
  width: 3rem;
`;

export const CheckboxContainer = Styled.div`
`;

export const Label = Styled.label`
  position: relative;
  display: flex;
  align-items: flex-start;
  font-size: 1.2rem;
  justify-content: flex-start;
  padding-bottom: 3rem;
  line-height: 2rem;

  span {
    color: ${colors.lightAccent};
    padding: .5rem;
  }
  @media ${breakpoints.tabletLandscape} {
    align-items: center;
  }
`;

export const Error = Styled.div`
  position: absolute;
  color: ${colors.red};
  bottom: 1rem;
  font-size: 1.2rem;
`;
