import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { H2, Paragraph } from '../../styles/commonStyles';
import useForm from '../../hooks/useForm';
import Button from '../../common/Button/Button';
import Checkbox from '../../common/Checkbox/Checkbox';
import FormField from '../FormField/FormField';
import Spinner from '../../common/Spinner/Spinner';

import { Column, ContactForm, Header, Image, Wrapper } from './Contact.style';

const propTypes = {
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]).isRequired,
};

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
);

const validPhoneRegexp = RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g);

const Contact = ({ forwardRef }) => {
  const { t } = useTranslation('translations');

  const stateValidatorSchema = {
    name: {
      required: true,
      validator: {
        func: value => value.length > 0,
        error: t('common.contact.form.name.error'),
      },
    },
    companyName: {
      required: true,
      validator: {
        func: value => value.length > 0,
        error: t('common.contact.form.company.error'),
      },
    },
    phoneNumber: {
      required: false,
      validator: {
        func: value => validPhoneRegexp.test(value),
      },
    },
    emailAddress: {
      required: true,
      validator: {
        func: value => validEmailRegex.test(value),
        error: t('common.contact.form.email.error'),
      },
    },
    message: {
      required: true,
      validator: {
        func: value => value.length > 0,
        error: t('common.contact.form.message.error'),
      },
    },
    checked: {
      required: true,
      validator: {
        func: value => value,
        error: t('common.contact.form.consent.error'),
      },
    },
  };

  const stateSchema = {
    name: { value: '', error: '' },
    companyName: { value: '', error: '' },
    phoneNumber: { value: '', error: '' },
    emailAddress: { value: '', error: '' },
    message: { value: '', error: '' },
    checked: { value: false, error: '' },
  };

  const [rows, setRows] = useState(4);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setCheckedBox] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  let resetForm;

  const onSubmitForm = async values => {
    const { name, companyName, emailAddress, message, phoneNumber } = values;
    const isBrowser = typeof window !== 'undefined';

    setIsLoading(true);

    if (isBrowser) {
      try {
        const response = await window.fetch('/.netlify/functions/send-mail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            text: `Wiadomość z formularza kontaktowego DEVBRAINS\nNadawca: ${name} (${emailAddress})\n${message}`,
            html: `
              <div>
                <h1>Wiadomość z formularza kontaktowego</h1>
                <p>Od ${companyName} ${name} (${emailAddress})</p>
                ${phoneNumber ? `<p>Telefon kontaktowy: ${phoneNumber}</p>` : ''}
                <p>${message}</p>
              </div>
            `,
          }),
        });

        setIsLoading(false);

        resetForm();
        setCheckedBox(false);

        if (response.ok) {
          setSuccessMessage(true);
          setTimeout(() => {
            setSuccessMessage(false);
          }, 3000);
        }
      } catch (error) {
        setIsLoading(false);
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  const { values, errors, handleOnChange, handleOnSubmit, handleResetForm, disable } = useForm(
    stateSchema,
    stateValidatorSchema,
    onSubmitForm,
  );

  resetForm = handleResetForm;

  const { name, emailAddress, phoneNumber, message, companyName } = values;

  const handleChangeMessage = e => {
    handleOnChange(e);

    const textareaLineHeight = 16;
    const previousRows = e.target.rows;
    e.target.rows = 4; // reset number of rows in textarea

    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    setRows(currentRows);
  };

  const handleChecked = () => {
    setCheckedBox(c => {
      const checkbox = {
        target: {
          name: 'checked',
          value: !c,
        },
      };
      handleOnChange(checkbox);
      return !c;
    });
  };

  return (
    <Wrapper ref={forwardRef}>
      {isLoading && <Spinner />}
      <Column left>
        <Header>
          <H2 theme="dark">{t('common.contact.header')}</H2>
        </Header>
        <Paragraph theme="dark" style={{ textAlign: 'center' }}>
          {t('common.contact.paragraph')}
        </Paragraph>
        {successMessage && <H2>{t('common.contact.sucessMessage')}</H2>}
        <ContactForm name="contactForm" onSubmit={handleOnSubmit}>
          <FormField
            onInputChange={handleOnChange}
            value={name}
            type="text"
            name="name"
            text={`${t('common.contact.form.name.placeholder')} *`}
            error={errors.name}
          />

          <FormField
            onInputChange={handleOnChange}
            value={companyName}
            type="text"
            name="companyName"
            text={`${t('common.contact.form.company.placeholder')} *`}
            error={errors.companyName}
          />

          <FormField
            onInputChange={handleOnChange}
            value={phoneNumber}
            type="text"
            name="phoneNumber"
            text={`${t('common.contact.form.phone.placeholder')}`}
            error={errors.phoneNumber}
          />

          <FormField
            onInputChange={handleOnChange}
            value={emailAddress}
            type="e-mail"
            name="emailAddress"
            text={`${t('common.contact.form.email.placeholder')} *`}
            error={errors.emailAddress}
          />

          <FormField
            onInputChange={handleChangeMessage}
            value={message}
            name="message"
            rows={rows}
            text={`${t('common.contact.form.message.placeholder')} *`}
            error={errors.message}
            textarea
          />

          <Checkbox
            text={`${t('common.contact.form.consent.text')} *`}
            checked={checked}
            name="agreement"
            error={errors.checked}
            handleSetChecked={handleChecked}
          />

          <Button block disabled={disable}>
            {t('common.contact.ctaText')}
          </Button>
        </ContactForm>
      </Column>
      <Column>
        <Image src="/img/home/contact.svg" alt="rocket" />
      </Column>
    </Wrapper>
  );
};

Contact.propTypes = propTypes;

export default Contact;
