import Styled from 'styled-components';

import { colors, breakpoints } from '../../styles/variables';

export const Container = Styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${colors.darkShade};
  padding: 5rem;
`;

export const ClientsWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding: 5rem 0;

  @media ${breakpoints.desktop} {
    justify-content: space-evenly;
  }
`;

export const Client = Styled.div`
  padding: 1rem 2rem;
  opacity: 1;
  filter: grayscale(100%);
  transition: filter 500ms;
  width: 85%;
  text-align: center;
  cursor: pointer;

  :hover {
    opacity: 1;
    filter: grayscale(0);
  }

  @media ${breakpoints.tabletPortrait} {
    width: 30%;
  }

  @media ${breakpoints.tabletLandscape} {
    width: 20%;
  }

  @media ${breakpoints.desktop} {
    width: 10%;
  }
`;

export default {
  Container,
  ClientsWrapper,
  Client,
};
