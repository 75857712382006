import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import data from '../Image/Image';
import { H2 } from '../../styles/commonStyles';

import { Container, ClientsWrapper, Client } from './Clients.style';

const propTypes = {
  heading: PropTypes.string,
};

const defaultProps = {
  heading: null,
};

const Clients = ({ heading }) => (
  <Container>
    {heading && <H2 theme="dark">{heading}</H2>}
    <ClientsWrapper>
      <Client>
        <a href="http://thebonbons.com" target="_blank" rel="noopener noreferrer">
          <img src="/img/thebonbons_logo.svg" width="150" alt="thebonbons logo" />
        </a>
      </Client>
      <Client>
        <a href="http://dominoconsulting.pl/" target="_blank" rel="noopener noreferrer">
          <Img fixed={data().domino.childImageSharp.fixed} alt="logo" />
        </a>
      </Client>
      <Client>
        <a href="https://starapiekarnia.com" target="_blank" rel="noopener noreferrer">
          <img src="/img/starapiekarnia_logo.svg" width="125" alt="Stara Piekarnia logo" />
        </a>
      </Client>
      <Client>
        <Img fixed={data().eclip.childImageSharp.fixed} alt="logo" />
      </Client>
      <Client>
        <a href="http://teatrrozbark.pl" target="_blank" rel="noopener noreferrer">
          <img src="/img/teatrrozbark_logo.svg" width="50" alt="Teatr Rozbark logo" />
        </a>
      </Client>
    </ClientsWrapper>
  </Container>
);

Clients.propTypes = propTypes;
Clients.defaultProps = defaultProps;

export default Clients;
