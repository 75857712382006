import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Hero from '../components/Hero/Hero';
import Clients from '../components/Clients/Clients';
import SEO from '../components/Seo/Seo';
import ContactForm from '../components/Contact/Contact';

const propTypes = {
  contactRef: PropTypes.shape({}).isRequired,
  handleClick: PropTypes.func.isRequired,
};

const IndexPage = props => {
  const { contactRef, handleClick } = props;
  const { t } = useTranslation('translations');

  return (
    <>
      <SEO title={t('pages.index.pageTitle')} />
      <Hero
        mirrored
        first
        picture="/img/home/web-development.svg"
        heading={t('pages.index.mainHeroHeading')}
        paragraph={t('pages.index.mainHeroParagraph')}
        buttonText={t('pages.index.mainHeroCtaLabel')}
        handleClick={handleClick}
      />
      <Hero
        picture="/img/home/mobile-development.svg"
        heading={t('pages.index.mobileHeroHeading')}
        paragraph={t('pages.index.mobileHeroParagraph')}
        theme="dark"
      />
      <Hero
        mirrored
        picture="/img/home/ux.svg"
        heading={t('pages.index.uxHeroHeading')}
        paragraph={t('pages.index.uxHeroParagraph')}
      />
      <Hero
        picture="/img/home/marketing.svg"
        heading={t('pages.index.marketingHeroHeading')}
        paragraph={t('pages.index.marketingHeroParagraph')}
        theme="dark"
      />
      <Hero
        mirrored
        picture="/img/home/learning.svg"
        heading={t('pages.index.knowledgeHeroHeading')}
        paragraph={t('pages.index.knowledgeHeroParagraph')}
      />
      <Hero
        picture="/img/home/testing.svg"
        heading={t('pages.index.qualityHeroHeading')}
        paragraph={t('pages.index.qualityHeroParagraph')}
        theme="dark"
      />
      <ContactForm forwardRef={contactRef} />
      <Clients heading={t('pages.index.ourClients')} />
    </>
  );
};

IndexPage.propTypes = propTypes;

export default IndexPage;
