import React from 'react';
import PropTypes from 'prop-types';

import {
  CheckboxContainer,
  HiddenCheckbox,
  Label,
  Icon,
  IconChecked,
  Error,
} from './Checkbox.style';

const propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleSetChecked: PropTypes.func.isRequired,
};

const defaultProps = {
  className: undefined,
  text: '',
  error: '',
};

const Checkbox = ({ className, checked, error, handleSetChecked, text, ...props }) => (
  <Label>
    <CheckboxContainer onChange={handleSetChecked} className={className}>
      <HiddenCheckbox defaultChecked={checked} props={props} />
      {checked ? <IconChecked /> : <Icon />}
    </CheckboxContainer>
    <span>{text}</span>
    {error && <Error>{error}</Error>}
  </Label>
);

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
